<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting sequence_wpr">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="forms-setting-form" class="forms-setting-form">
                    <div class="sequence_details">
                        <div class="acton_header mb-4">
                            <div class="left_section">
                                <div class="section_header">
                                    <h2>Message</h2>
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Category <a class="option" @click="createCategoryToggle = !createCategoryToggle">Create a category</a></label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.category }">
                                        <Field autocomplete="off" name="category" v-model="form.category" rules="required" label="category">
                                            <multiselect
                                                v-model="form.category"
                                                value-prop="type"
                                                label="type"
                                                :searchable="true"
                                                :options="categories"
                                                placeholder="Select category">
                                            </multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="category" class="text-danger" />
                                </div>
                            </div>
                            <div v-if="createCategoryToggle" class="form_grp">
                                <Form v-slot="{ errors }" ref="category-form">
                                    <div class="group_item">
                                        <label class="input_label">Create a New Category</label>
                                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.type }">
                                            <Field autocomplete="off" type="text" v-model="categoryForm.type" name="type" placeholder="Enter Category Name.." rules="required" />
                                            <span class="suffix pointer" @click="handleCategoryCreate"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Creating' : 'Create' }}</span>
                                        </div>
                                        <ErrorMessage name="type" class="text-danger" />
                                    </div>
                                </Form>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Template Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                        <Field autocomplete="off" type="text" name="title" v-model="form.title" placeholder="ex: New Category" rules="required" label="name" />
                                    </div>
                                    <ErrorMessage name="title" class="text-danger" />
                                </div>
                            </div>
                            <div class="mt-2" v-if="templateType == 'content'">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Content</label>
                                        <div class="editorWpr mb-4" :class="{ 'has-error': errors.content }">
                                            <Field autocomplete="off" name="content" v-model="form.content" :class="{ 'has-error': errors.content }" rules="required_editor">
                                                <redactor v-model="form.content" name="content" />
                                            </Field>
                                        </div>
                                        <ErrorMessage name="content" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3" v-if="templateType == 'multi'">
                                <label for="mail" class="switch_option capsule_btn">
                                    <h5>E-mail<span>Send E-mail Notification.</span></h5>
                                    <input type="checkbox" id="mail" value="Email" v-model="temp.mail" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="sms" class="switch_option capsule_btn">
                                    <h5>SMS<span>Send SMS Notification.</span></h5>
                                    <input type="checkbox" id="sms" value="SMS" v-model="temp.sms" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="mt-2" v-if="templateType == 'email' || temp.mail">
                                <email-component v-model="deliveryEmail" :errors="errors" preview-module="templates" ref="respondent-email-component" />
                            </div>
                            <div class="mt-2" v-if="templateType == 'sms' || temp.sms">
                                <sms-component v-model="form.sms_content" media-field-name="smsMedia" :media="form.sms_media" :default-sms-handler="true" preview-module="templates" />
                            </div>
                        </div>
                    </div>
                    <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    <div class="action_wpr">
                        <button :disabled="loader && !createCategoryToggle" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button :disabled="loader && !createCategoryToggle" type="submit" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader && !createCategoryToggle"></i> {{ loader && !createCategoryToggle ? 'Saving' : 'Save' }}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview}">
                <div class="header">
                    <div class="section_header m-0">
                        <button type="button" class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_message" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_message" v-model="previewMsg" hidden />
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev" v-if="temp.mail && temp.sms">
                    <li @click="notificType = 'email'" :class="{'active' : notificType == 'email'}">E-mail</li>
                    <li @click="notificType = 'sms'" :class="{'active' : notificType == 'sms'}">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="getPreviewType() == 'email'">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(deliveryEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/robert.png" alt="">
                                <div class="info">
                                    <h5>{{ user.businessname ? user.businessname : 'Thrive Coach'}}</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="deliveryEmail.show_email_logo == 1">
                                <img :src="deliveryEmail.email_logo ? deliveryEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(deliveryEmail.email)"></div>
                            <div v-html="generateSignature(deliveryEmail.is_signature, deliveryEmail.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="getPreviewType() == 'sms'">
                        <div class="sms_preview">
                            <p v-html="form.sms_content"></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="templateType == 'content'">
                        <div class="content_preview">
                            <p v-html="form.content"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Preview-message v-model="previewMsg" :delivery-email="deliveryEmail" :content="form.content" :sms="form.sms_content" :category="getPreviewType()" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Form, Field, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const PreviewMessage = defineAsyncComponent(() => import('@/pages/templates/components/PreviewMessage'))

    export default {
        name: 'Edit Template',

        data () {
            return {
                notificType: 'email',
                preview: true,
                previewMsg: false,
                temp: {
                    mail : false,
                    sms: false,
                },
                deliveryEmail: {
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                form: {
                    title: '',
                    sms_content: '',
                    email_content: '',
                    content: '',
                    type: '',
                    category: '',
                    is_editor: false,
                    sms_media: [],
                },
                whiteLabel: {},
                smsMedia: [],
                createCategoryToggle: false,
                categoryForm: {
                    type: '',
                    is_content: 'email',
                },
            }
        },

        props: {
            modelValue: Boolean,
            templateType: String,
            categories: Array,
            refreshTemplate: Function,
            refreshCategory: Function,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            PreviewMessage,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                    vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                loader: state => state.templateModule.templateComponentLoader,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;
            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted () {
            const vm = this;
            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                createTemplate: 'templateModule/createTemplate',
                createCategory: 'templateModule/createCategory',
                getCategories: 'templateModule/getCategories',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            resetForm () {
                const vm = this;

                vm.notificType   = 'email';
                vm.preview       = true;
                vm.previewMsg    = false;
                vm.temp          = { mail : false, sms: false };
                vm.deliveryEmail = {
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                };
                vm.form = {
                    title: '',
                    sms_content: '',
                    email_content: '',
                    content: '',
                    category: '',
                    is_editor: 1,
                    sms_media: [],
                };

                vm.form.type = vm.templateType != 'multi' ? [vm.templateType] : ['email', 'sms'];
            },

            handleSubmitForm (params, { setFieldError }) {
                const vm = this;

                const removeItems  = ['contact_id', 'email_button_bg_color', 'email_button_text_color', 'is_email_conf', 'source', 'email', 'is_email_logo'];
                let form           = { ...vm.form, ...vm.deliveryEmail };
                form.email_content = form.email;
                form.sms_media     = vm.smsMedia.join(',');

                removeItems.forEach((item) => { delete form[item] });

                form.setFieldError = setFieldError;

                vm.createTemplate(form).then((result) => {
                    if (result) {
                        vm.refreshTemplate();
                        vm.closeModal();
                    }
                });
            },

            getPreviewType () {
                const vm = this;

                if (vm.templateType !== 'multi') {
                    return vm.templateType;
                } else if (!vm.temp.mail && !vm.temp.sms) {
                    return 'email';
                } else if ((vm.notificType == 'email' && vm.temp.mail) || (vm.notificType == 'sms' && !vm.temp.sms )) {
                    return 'email';
                } else if ((vm.notificType == 'sms' && vm.temp.sms) || (vm.notificType == 'email' && !vm.temp.mail )) {
                    return 'sms';
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            handleCategoryCreate () {
                const vm = this;
                const form = vm.$refs['category-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        let type = 1;

                        if (vm.templateType == 'email') {
                            type = 1;
                        } else if (vm.templateType == 'content') {
                            type = 2;
                        } else if (vm.templateType == 'sms') {
                            type = 3;
                        } else if (vm.templateType == 'voice') {
                            type = 4;
                        }

                        vm.categoryForm.setFieldError = form.setFieldError;
                        vm.categoryForm.is_content    = type;

                        vm.createCategory(vm.categoryForm).then((result) => {
                            vm.createCategoryToggle = false;
                            vm.categoryForm.type    = '';
                            vm.getCategories().then((result) => {
                                vm.refreshCategory(vm.templateType);
                            });
                        });
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .sequence_wpr .sequence_details {
        flex: 1;
        padding: 30px 50px;
    }

    .global_setting .tabs_content form > .action_wpr {
        padding: 25px 40px;
    }

    .forms-setting-form  {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .sequence_card {
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 8px;
    }

    .sequence_card h4 {
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
        color: #121525;
        padding: 10px 20px;
        border-bottom: 1px solid #e9e9e9;
    }

    .sequence_card .card_info {
        padding: 15px 20px;
    }

    .sequence_card .card_info p {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #757575;
        margin-bottom: 10px;
    }

    .sequence_card .card_info .action {
        display: flex;
        justify-content: space-between;
    }

    .sequence_list {
        padding-top: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;
    }

    .sequence_list > li {
        border-radius: 8px;
        padding-bottom: 70px;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .sequence_list > li:not(:first-child) {
        padding-top: 30px;
    }

    .sequence_list > li:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
        z-index: -1;
    }

    .sequence_list .sequence_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .sequence_list .sequence_item li:not(:last-child) {
        padding-bottom: 40px;
    }

    .sequence_wpr .step_type {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 1;
    }

    .sequence_wpr .add_new {
        width: 40px;
        height: 40px;
        font-size: 15px;
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #5a5a5a;
        cursor: pointer;
    }

    .sequence_list li .card_details {
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 500;
    }

    .sequence_list li .card_details p {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        margin: 3px 0;
    }

    .sequence_list li .step_info {
        border: 1px solid #e9e9e9;
        background: #fff;
        position: relative;
        overflow: hidden;
    }

    .sequence_list li .step_info.active {
        border: 2px solid #bad7ff;
    }

    .sequence_list li .step_info.draft > *:not(button) {
        opacity: 0.5;
    }

    .sequence_list li .remove_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        position: absolute;
        right: -25px;
        top: 7px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .sequence_list li .remove_btn i {
        color: #5a5a5a !important;
    }

    .sequence_list li .step_info:hover .remove_btn {
        right: 10px;
    }

    .sequence_list li .step_info.timer {
        padding: 10px 20px;
        border-radius: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 230px;
    }

    .sequence_list li .step_info.mail {
        border-radius: 8px;
        min-width: 300px;
    }

    .sequence_list li .step_info.timer i {
        color: #b9b9b9;
    }

    .sequence_list li .step_info.timer h4 {
        flex: 1;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .sequence_list li .step_info.mail h4 {
        text-align: center;
        padding: 12px 20px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        position: relative;
    }

    .sequence_list li .step_info.mail h4:hover {
        cursor: move;
    }

    .sequence_list li .step_info.mail h4 label {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background: #9b9b9b;
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        left: 20px;
        top: 13px;
        transition: all 0.3s ease-in-out;
    }

    .sequence_list li .step_info.mail .msg_cont .mail_box, .sequence_list li .step_info.mail .msg_cont .sms_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 20px 15px 20px;
        border-top: 1px solid #e9e9e9;
    }

    .sequence_list li .step_info.mail .msg_cont .mail_box .status_area, .sequence_list li .step_info.mail .msg_cont .sms_box .status_area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sequence_list li .step_info.mail .msg_cont .mail_box .score_circle {
        width: 30px;
        height: 30px;
        padding: 2px;
    }

    .sequence_list li .step_info.mail .msg_cont .mail_box .score_circle .inner_circle .score {
        font-size: 15px;
        line-height: 18px;
    }

    .sequence_list li .step_info.mail .msg_cont .msg_desc {
        border: 1px dashed #c9c9c9;
        padding: 10px;
        text-align: center;
        margin: 5px 0 10px;
        min-width: 200px;
    }

    .sequence_list li .step_info.mail .msg_cont .msg_desc p {
        max-height: 36px;
        font-size: 13px;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .step_type.active .add_types {
        display: block;
    }

    .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .add_types h3 {
        font-size: 13px;
        line-height: 17px;
        color: #121525;
        font-weight: 500;
        padding: 12px 15px;
    }

    .add_types ul {
        display: flex;
        justify-content: center;
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }

    .add_types ul li {
        width: 100%;
        padding: 10px;
    }

    .add_types ul li i {
        font-size: 25px;
        color: #c4c4c4;
        margin-bottom: 10px;
    }

    .add_types ul li h4 {
        font-weight: 500;
    }

    .sequence_wpr .left_bar .primary_btn {
        font-size: 15px;
        line-height: 18px;
        height: 40px;
    }

    .sequence_wpr .left_bar .extra_info {
        padding: 15px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        background: #e4e4e4;
        border: 0;
    }

    .sequence_wpr .tabs_content .extra_info {
        padding: 20px;
        font-size: 12px;
        line-height: 19px;
        font-weight: 500;
        color: #121525;
        display: block;
        margin-bottom: 15px;
        background: #e4e4e4;
        border: 1px solid #d9d9d9;
    }

    .sequence_wpr .tabs_content .extra_info a span {
        color: #2f7eed;
    }

    .sequence_details .acton_header .extra_info {
        padding: 15px;
        font-size: 13px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;
    }

    .sequence_details .acton_header .extra_info span {
        color: #2f7eed;
        font-weight: 500;
        padding: 0 5px;
        cursor: pointer;
    }

    .multiselect.day_input {
        min-height: 25px;
        border: 1px solid #a0c7ff;
        padding: 0 10px;
        margin: 0 5px;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-weight: 500;
        text-align: center;
        width: 80px;
    }

    .sqr_input {
        height: 25px;
        border: 1px solid #a0c7ff;
        padding: 0 10px;
        margin: 0 5px;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-weight: 500;
        text-align: center;
        width: 30px;
    }

    .sqr_input::-webkit-outer-spin-button, .sqr_input::-webkit-inner-spin-button  {
        -webkit-appearance: none;
        margin: 0;
    }

    .sqr_input[type=number] {
        -moz-appearance: textfield;
    }

    .preview_content {
        width: 550px;
        padding: 0 40px;
        position: relative;
        margin-right: -550px;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }

    .preview_content.show  {
        margin-right: 0;
    }

    .preview_btn  {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn  {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .modal_container .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr  {
        width: 100%;
    }

    .analytics {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -10px 0 -10px;
    }

    .analytics li {
        padding: 10px;
        width: 20%;
    }

    .analytics_card {
        border: 1px solid #eaeaea;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .analytics_card .score_circle {
        width: 50px;
        height: 50px;
        padding: 3px;
    }

    .analytics_card .score_circle .inner_circle .score {
        font-size: 13px;
        line-height: 16px;
    }

    .analytics_card h4 {
        font-size: 12px;
        line-height: 15px;
        color: #121525;
        font-weight: 400;
        margin-top: 10px;
    }

    .analytics_card:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    .result_wpr.small .actions > ul {
        height: 35px;
    }

    .result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions > ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td  {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img, .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4  {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 10px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 30px auto;
        /* transform: scale(0.8); */
    }

    .cell:after, .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after, .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p, .cell .sms_preview p, .cell .msg_preview p :deep(p), .cell .sms_preview p :deep(p) {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .cell .msgFooter p  {
        font-size: 11px;
        line-height: 17px;
    }

    .cell .content_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 40px 15px;
        position: relative;
        border-radius: 10px;
    }

    .cell .content_preview p {
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 15px;
        /* border-bottom: 1px solid #eaeaea; */
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    :deep(.preview_area .area_header .logo_wpr img) {
        width: 40px
    }
</style>
